<ng-container *ngIf="treeControl">
  <div class="rvc-sidebar float-start">
    <nx-sidebar #sidebar [minWidth]="70">
      <div class="menu-tree">
        <div class="d-flex justify-content-center align-items-center w-100 nx-margin-bottom-s logo">
          <img [ngStyle]="{width: sidebar.open ? '175px' : '35px'}"
               [src]="sidebar.open ? 'assets/img/rvc-logo-long.png' : 'assets/img/rvc_ico.png'"
               alt="Brand Logo"
               role="none"
          />
        </div>
        <div class="scroll-shadow" [ngClass]="{'active-top-scroll-shadow': applyTopScrollShadow}"></div>
        <div class="menus" #menus (scroll)="onScroll()" (resize)="onScroll()">
          <nx-tree #treeComponent [dataSource]="dataSource" [treeControl]="treeControl" (resize)="onScroll()">
            <nx-tree-node *nxTreeNodeDef="let node">
              <ng-container *ngIf="sidebar.open">
                <button nxAction
                        nxTreeNodeActionItem
                        nxTreeNodePadding
                        [routerLink]="'/'+prefix+(node.prefix?'/'+node.prefix:'')+'/'+node.query"
                        routerLinkActive="is-selected"
                        type="button">
                  <nx-icon *ngIf="node.icon" aria-label=""
                           nxActionIcon
                           [name]="node.icon"
                  ></nx-icon>
                  <ng-container> {{node.label | caption}}</ng-container>
                </button>
              </ng-container>
              <ng-container *ngIf="!sidebar.open">
                <button nxAction
                        nxTreeNodeActionItem
                        [routerLink]="'/'+prefix+(node.prefix?'/'+node.prefix:'')+'/'+node.query"
                        routerLinkActive="is-selected"
                        type="button">
                  <nx-icon *ngIf="node.icon" aria-label=""
                           nxActionIcon
                           [name]="node.icon"
                  ></nx-icon>
                  <ng-container> {{node.label | caption}}</ng-container>
                </button>
              </ng-container>
            </nx-tree-node>
            <nx-tree-node *nxTreeNodeDef="let node; when: hasChild">
              <button nxAction
                      nxTreeNodeToggle
                      nxTreeNodeActionItem
                      expandable
                      [expanded]="treeControl.isExpanded(node)"
                      type="button">
                <nx-icon *ngIf="node.icon" aria-label=""
                         nxActionIcon
                         [name]="node.icon"></nx-icon>
                <ng-container> {{node.label | caption}}</ng-container>
              </button>
            </nx-tree-node>
          </nx-tree>
        </div>
        <div class="scroll-shadow" [ngClass]="{'active-bottom-scroll-shadow': applyBottomScrollShadow}"></div>
        <div class="footer">
          <button nxAction type="button" [nxContextMenuTriggerFor]="menu" aria-label="Profile">
            <nx-icon nxActionIcon name="user-o"></nx-icon>
            <span class="fullname">{{authUser.lastName}} {{authUser.firstName}}</span><br>{{authUser.email}}
          </button>
          <button nxAction type="button" (click)="toggleSidebar()">
            <nx-icon nxActionIcon [name]="sidebar.open ? 'chevron-left' : 'chevron-right'"></nx-icon>
            {{'general.collapse-menu' | caption}}
          </button>
        </div>
      </div>
    </nx-sidebar>
  </div>
</ng-container>

<nx-context-menu #menu="nxContextMenu">
  <ng-container *ngIf="authUser">
    <button nxContextMenuItem type="button" (click)="logout()">
      <nx-icon aria-hidden="true" name="arrow-right-from-bracket"></nx-icon>
      {{'general.logout' | caption}}
    </button>
  </ng-container>
</nx-context-menu>
