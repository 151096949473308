import {Component, Input, OnInit} from '@angular/core';
import {AddressModel} from "../../modal/add-address-to-model/address.model";
import {AbstractBaseComponent} from "common";
import {COUNTRIES} from "../../../utilities/constants";

@Component({
  selector: 'forms-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent extends AbstractBaseComponent implements OnInit {
  @Input() address: AddressModel

  countries = COUNTRIES;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
