import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddonsComponent} from './addons/addons.component';
import {RouterModule} from '@angular/router';
import {EditAddonsComponent} from './edit-addons/edit-addons.component';
import {ShowAddonsComponent} from './show-addons/show-addons.component';
import {NxCardModule} from '@aposin/ng-aquila/card';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {ComponentsModule} from '../../components/components.module';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {FormsModule} from '@angular/forms';
import {NxDataDisplayModule} from "@aposin/ng-aquila/data-display";
import {PipeModule} from "../../pipe/pipe.module";


@NgModule({
  declarations: [
    AddonsComponent,
    EditAddonsComponent,
    ShowAddonsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NxCardModule,
    NxIconModule,
    ComponentsModule,
    NxHeadlineModule,
    NxGridModule,
    NxButtonModule,
    NxFormfieldModule,
    NxInputModule,
    FormsModule,
    NxDataDisplayModule,
    PipeModule,
  ]
})
export class AddonModule {
}
