import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MEASURING_DEVICE_STATUSES, MeasuringDevice} from '../../../models/measuring-device.model';
import {lastValueFrom} from 'rxjs';
import {AUTH_USER_KEY, DEVICE_STATUSES, ROLE_INDEX} from '../../../utilities/constants';
import {KeycloakProfile} from 'keycloak-js';
import {MeasuringDeviceService} from '../../../services/model/measuring-device.service';
import {MyStorageService} from "../../../services/my-storage.service";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {ShowQrCodeComponent} from "../../../components/modal/show-qr-code/show-qr-code.component";
import {Utilities} from "../../../utilities/utilities";
import {PageTest} from "../../../utilities/PageTest";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {ROUTING} from "../../../utilities/routing-constants";
import {AbstractListPage} from "../../../models/AbstractListPage";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {TableAction, TableCol, TableFilter, TableSort} from "ng-aquila-datatable";
import {AbstractCaptionService} from "irf-caption";
import {FilterService} from "../../../services/filterer/filter.service";
import {RawDataRepository} from "../../../repositories/raw-data-repository";
import * as moment from "moment";
import {Distributor} from "../../../models/distributor.model";

@Component({
  selector: 'app-measuring-devices',
  templateUrl: './measuring-devices.component.html',
  styleUrls: ['./measuring-devices.component.scss']
})
@PageTest({
  path: ROUTING.MeasuringDevice.basePlural,
  layout: AdminLayoutComponent
})
export class MeasuringDevicesComponent extends AbstractListPage<MeasuringDevice[], MeasuringDeviceService> implements OnInit {
  statuses = DEVICE_STATUSES;
  tableSort: TableSort = new TableSort('externalId', 'asc');
  colDef: TableCol[] = [];
  actionDef: TableAction[];
  componentDialogRef!: NxModalRef<any>;
  endDate: any = moment();
  startDate: any = moment(Utilities.dateToBackendString(Utilities.addDays(new Date(), -7)));

  today: any = moment();
  @ViewChild('template') templateRef!: TemplateRef<any>;
  templateDialogRef?: NxModalRef<any>;

  @ViewChild('test') hdrTpl: TemplateRef<any>;
  @ViewChild('colorTemplate') colorTemplate: TemplateRef<any>;
  @ViewChild('translateTemplate') translateTemplate: TemplateRef<any>;
  listView: boolean = false;
  selected: MeasuringDevice;


  constructor(viewportService: NxViewportService,
              cdr: ChangeDetectorRef,
              translateService: AbstractCaptionService,
              service: MeasuringDeviceService, myStorageService: MyStorageService,
              public dialogService: NxDialogService,
              private filterer: FilterService,
              private rawDataRepo: RawDataRepository,) {
    super(viewportService, cdr, translateService, service, myStorageService);

  }

  ngOnInit(): void {
    this.service.copyId().next(null);
    this.loadData();
  }

  private async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('qrcode', this.openQrCode(), await this.getTranslate('table.action.qrCode')),
      new TableAction('eye', this.show(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.edit(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('copy', this.copy(), await this.getTranslate('table.action.copy'), this.rowPermission()),
      new TableAction('trash', this.delete(), await this.getTranslate('table.action.delete'), this.rowPermission()),
      new TableAction('download', this.openExportModal(), await this.getTranslate('table.action.download'), this.rowPermission()),
    ];
    this.colDef = [
      TableCol.newString('externalId', await this.getTranslate('measuringDevice.externalId')),
      TableCol.newString('uuId', await this.getTranslate('measuringDevice.uuId')),
      TableCol.newString('status', await this.getTranslate('measuringDevice.status'), TableFilter.newDefaultText(), () => {
        return this.translateTemplate
      }),

      TableCol.newString('distributor.name', await this.getTranslate('measuringDevice.distributor')),
      TableCol.newBoolean('virtual', await this.getTranslate('measuringDevice.virtual')),
      TableCol.newBoolean('online', await this.getTranslate('measuringDevice.online')),
      TableCol.newString('color', await this.getTranslate('measuringDevice.color'), TableFilter.newDefaultText(), () => {
        return this.colorTemplate
      }),
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted());
    this.models.forEach(md => {
      if (md.distributor == null) {
        md.distributor = new Distributor();
        md.distributor.name = "";
      }
    });
  }

  private openQrCode(): (row: any) => void {
    return (row: any) => {
      this.componentDialogRef = this.dialogService.open(
        ShowQrCodeComponent,
        Utilities.getDefaultModalConfig(JSON.stringify({'id': row.id, 'uuId': row.uuId}))
      );
    };
  }

  private delete(): (row: any) => void {
    return async (row: any) => {
      this.service.notificationService.confirm('Figyelmeztetés', 'Biztos hogy törlöd?', 'general.ok', 'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.service.repo.deleteById(row.id));
          this.loadData();
        }
      });
    }
  }

  async changeStatus(row: MeasuringDevice, key: MEASURING_DEVICE_STATUSES) {
    await lastValueFrom(this.service.repo.patchStatus(row.id, key));
    this.service.notificationService.showSuccess('general.success', 'general.updateWasSuccess');
    this.loadData();
  }


  openExportModal(): (row: any) => void {
    return (row) => {
      this.selected = row;
      this.templateDialogRef = this.dialogService.open(this.templateRef, {
        ariaLabel: 'Export dialog',
      });
    }

  }

  async export() {
    const filter = this.filterer.filter('MetaDataDTO')
      .greaterThanEqual('startOfMeasuringPeriod', Utilities.setStartOfDay(Utilities.dateToBackendDateTimeString(this.startDate)))
      .and()
      .lessThanEqual('endOfMeasuringPeriod', Utilities.setEndOfDay(Utilities.dateToBackendDateTimeString(this.endDate)))
      .and()
      .equal('measuringDeviceUUID', this.selected.externalId)
      .create();
    const metaData = await lastValueFrom(this.rawDataRepo.getAllByFilter(filter));
    const rawData = metaData.map(md => md.rawData).map(rd => JSON.parse(rd.rawData));
    Utilities.downloadFile(rawData);
    this.templateDialogRef?.close();
  }

  closeModal() {
    this.templateDialogRef?.close();
  }

  private copy(): (row: any) => void {
    return (row: any) => {
      this.service.copyId().next(row.id);
      this.service.new();
    };
  }
}
