<nx-card class="table-card-viewer">
  <page-list-view-header
    [title]="'partner.headlineApiKey'"
    [menuWantedInMobileView]="false"
    [onlyAddNewButtonWantedInMobileView]="false"
  ></page-list-view-header>
  <ng-container *ngIf="partners">
    <ng-container *ngFor="let partner of partners">
      <nx-card class="viewer nx-margin-bottom-2m">
        <nx-toolbar class="justify-content-between d-flex">

          <h4 nxHeadline="subsection-small" class="back-right">{{partner.name}}</h4>

          <button nxButton="primary small" type="button" (click)="generateApiKey(partner)">
            <nx-icon name="arrows-rotate" class="nx-margin-right-2xs"></nx-icon>
            {{'general.generateNew' | caption}}
          </button>

        </nx-toolbar>
        <div class="row">
          <div class="col-10 mx-auto">
            <p class="p-2 d-flex align-items-center" *ngIf="partner.apiKey && partner.apiKey.length>0">
              <b>{{'measuringDevice.apiKey' | caption}}:</b>&nbsp;
              <span class="d-none d-xl-block">{{partner.apiKey}}</span>
              <span class="d-block d-xl-none">{{partner.apiKey | shortenIfMobile:true}}</span>
              <button class="ms-2" nxPlainButton (click)="copyToClipboard(partner.apiKey)">
                <nx-icon name="copy"></nx-icon>
              </button>
            </p>
            <p class="p-2" *ngIf="!partner.apiKey || partner.apiKey.length<1">
              <b>{{'partner.noApiKey' | caption}} </b>
            </p>
          </div>
        </div>
      </nx-card>
    </ng-container>
  </ng-container>
</nx-card>
