<div nxLayout="grid">
  <div nxRow="">
    <div nxCol="12,12,6,3">
      <nx-formfield nxLabel="{{'general.address.countryCode' | caption}} *">
        <nx-dropdown [(ngModel)]="address.countryCode">
          <nx-dropdown-item
            *ngFor="let country of countries"
            [nxValue]="country.code.toUpperCase()"
          >{{country.code.toLowerCase()}} - {{country.name}}</nx-dropdown-item>
        </nx-dropdown>
      </nx-formfield>
    </div>
    <div nxCol="12,12,6,5">
      <app-input
        captionCode="general.address.country"
        name="country"
        type="text"
        [required]="true"
        [(ngModel)]="address.country"
      ></app-input>
    </div>

    <div nxCol="12,12,6,4">
      <app-input
        captionCode="general.address.city"
        name="city"
        type="text"
        [required]="true"
        [(ngModel)]="address.city"
      ></app-input>
    </div>
    <div nxCol="12,12,3,2" nxColOffset="0,0,9,0">
      <app-input
        captionCode="general.address.zipCode"
        name="zipCode"
        type="text"
        [required]="true"
        [(ngModel)]="address.zipCode"
      ></app-input>
    </div>
    <div nxCol="12,12,6,5" >
      <app-input
        captionCode="general.address.address"
        name="address"
        type="text"
        [required]="true"
        [(ngModel)]="address.address"
      ></app-input>
    </div>
    <div nxCol="12,12,6,5">
      <app-input
        captionCode="general.address.houseNumber"
        name="houseNumber"
        type="text"
        [required]="true"
        [(ngModel)]="address.houseNumber"
      ></app-input>
    </div>
  </div>
</div>
