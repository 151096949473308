import {Component, Inject, OnInit} from '@angular/core';
import {Contract} from "../../../models/contract.model";
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";

export interface InputToContractModal {
  model: Contract;
  device?: any[];
  points?: any[];
  partners?: any[];
  denyDates:Date[];
  distributors?:any[];
  asDistributor?:boolean;
}

@Component({
  selector: 'app-add-contract-to-model',
  templateUrl: './add-contract-to-model.component.html',
  styleUrls: ['./add-contract-to-model.component.scss']
})
export class AddContractToModelComponent implements OnInit {

  contract: Contract;

  constructor(@Inject(NX_MODAL_DATA) public data: InputToContractModal,) {
  }

  ngOnInit(): void {
    if (this.data.model) {
      this.contract = {...this.data.model};
      if (this.data.model.measuringPoint.distributor?.id) {
        this.contract.distributorId = this.data.model.measuringPoint.distributor.id;
      }
    } else {
      this.contract = new Contract();
    }
  }

}
