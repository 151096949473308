import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddressPipe} from "./address.pipe";
import {SecureImgPipe} from './secure-img.pipe';
import {DateAgoPipe} from './date-ago.pipe';
import {JointPipe} from './joint.pipe';
import { ByteToPipe } from './byte-to.pipe';
import { SkipInvalidItemPipe } from './skip-invalid-item.pipe';
import {ShortenIfMobilePipe} from "./shorten-if-mobile.pipe";


@NgModule({
  declarations: [AddressPipe, SecureImgPipe, DateAgoPipe, JointPipe, ByteToPipe, SkipInvalidItemPipe, ShortenIfMobilePipe],
  imports: [
    CommonModule
  ],
    exports: [AddressPipe, SecureImgPipe, DateAgoPipe, JointPipe, ByteToPipe, SkipInvalidItemPipe, ShortenIfMobilePipe]
})
export class PipeModule { }
