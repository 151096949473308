import {Component, OnInit} from '@angular/core';
import {lastValueFrom, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Addon} from '../../../models/addon.model';
import {AddonService} from '../../../services/model/addon.service';
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {MyStorageService} from "../../../services/my-storage.service";

@Component({
  selector: 'app-edit-addons',
  templateUrl: './edit-addons.component.html',
  styleUrls: ['./edit-addons.component.scss']
})
@PageTest({
  path: ['edit', 'edit/:id'],
  pathPrefix: ROUTING.Addon.basePlural,
})
export class EditAddonsComponent implements OnInit {

  private sub: Subscription;
  model: Addon = new Addon();

  constructor(private service: AddonService,
              private route: ActivatedRoute,
              private myStorageService: MyStorageService) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(async params => {
      let id = params['id'];
      if (id) {
        this.model = await lastValueFrom(this.service.repo.getById(id));
      }
      if (this.model == null) {
        this.model = new Addon();
      }
    });
  }

  save(): () => void {
    return async () => {
      try {
        this.service.repo.validate(this.model);
        if (!this.model.id) {
          await lastValueFrom(this.service.repo.save(this.model));
        } else {
          await lastValueFrom(this.service.repo.update(this.model.id, this.model));
        }
        await this.service.notificationService.showSuccess('general.success', 'general.success')
        this.service.navigateToBaseOrDetailedView(this.model.id, this.myStorageService.getFromCookies('NAVIGATE_TO_DETAILED_VIEW_AFTER_EDIT', false));
      } catch (e) {
        console.log(e);
      }
    };
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  cancel(): () => void {
    return () => {
      this.service.navigateToBaseOrDetailedView(this.model.id, this.myStorageService.getFromCookies('NAVIGATE_TO_DETAILED_VIEW_AFTER_EDIT', false));
    }
  }
}
