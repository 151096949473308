<form class="form-example nx-margin-top-s">
  <div nxLayout="grid">
    <div nxRow="">
      <div nxCol="12,12,12,8">
        <div nxRow="">
          <div nxCol="12,12,12,4">
            <app-input
              captionCode="general.name"
              type="text"
              name="name"
              [required]="true"
              [(ngModel)]="model.name"
            ></app-input>
          </div>
          <div nxCol="12,12,6,3">
            <app-input
              captionCode="general.latitude"
              type="text"
              name="latitude"
              [required]="true"
              [(ngModel)]="model.latitude"
            ></app-input>
          </div>
          <div nxCol="12,12,6,3">
            <app-input
              captionCode="general.longitude"
              type="text"
              name="longitude"
              [required]="true"
              [(ngModel)]="model.longitude"
            ></app-input>
          </div>
          <div nxCol="12,12,6,2">
            <app-input
              captionCode="general.threshold"
              type="number"
              name="threshold"
              [required]="true"
              [(ngModel)]="model.threshold"
            ></app-input>
          </div>
          <div nxCol="12,12,12,12">
            <nx-formfield nxLabel="{{'general.description'|caption}}">
         <textarea name="description" [(ngModel)]="model.description" nxInput cdkTextareaAutosize
                   required></textarea>
            </nx-formfield>
          </div>
          <div nxCol="12,12,12,6">
            <nx-formfield nxLabel="{{'general.timezone' | translate}}">
              <nx-dropdown [(ngModel)]="model.timezone" name="timezone" (selectionChange)="timezoneChange()"
                           [nxShowFilter]="true"
                           nxFilterPlaceholder="{{'general.typeToSearch' | translate}}">
                <ng-container *ngFor="let zoneGroup of timezones">
                  <nx-dropdown-group [nxLabel]="zoneGroup.continent">
                    <ng-container *ngFor="let timezone of zoneGroup.newRow">
                    <nx-dropdown-item
                      [nxValue]="timezone.tz"
                    >{{timezone.tz}} {{calculateUtc(timezone.tz)}}</nx-dropdown-item>
                    </ng-container>
                  </nx-dropdown-group>
                </ng-container>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <ng-container *ngIf="distributors">
            <div nxCol="12,12,12,6">
              <nx-formfield nxLabel="{{'general.distributor' | translate}}">
                <nx-dropdown [(ngModel)]="distributorId" (selectionChange)="selectionChange()" name="distributor">
                  <nx-dropdown-item
                    *ngFor="let distributor of distributors"
                    [nxValue]="distributor.id"
                  >
                    {{distributor.name}}
                  </nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </ng-container>

        </div>
      </div>
      <div nxCol="12,12,12,4">
        <div nxRow="">
          <div nxCol="12,12,12, 6">
            <p>{{'measuringDevice.attachment' | caption}}</p>
            <ng-container *ngIf="model.attachment">
              <figure nxFigure="">
                <img
                  [src]="model.attachment.srcWidth"
                  [alt]="model.name"
                  (error)="model.attachment.srcWidth = 'assets/img/default.png'"
                />
              </figure>
            </ng-container>
          </div>
          <ng-container *ngIf="model.note">
            <div nxCol="12,12,12, 6">
              <p>{{'measuringDevice.note' | caption}}</p>
              <p class="nx-margin-left-s">"{{model.note.message}}"</p>
            </div>
          </ng-container>
          <ng-container *ngIf="model.address && model.address.city">
          <div nxCol="12">
            <nx-data-display class="nx-margin-bottom-s nx-margin-top-m" label="{{'measuringPoint.address' | caption}}"
                             orientation="vertical">
              {{model.address | address}}
            </nx-data-display>
          </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>
