<nx-card class="table-card-viewer">
    <page-list-view-header
      [title]="'contract.headline'"
      [(viewChangers)]="viewChangers"
      [menuWantedInMobileView]="true"
      [onlyAddNewButtonWantedInMobileView]="false"
    ></page-list-view-header>

  <ng-container *ngIf="isReady">
    <div nxRow="">
      <div nxCol="12">
        <ng-template [ngIf]="viewChangers['tableView'].show">
          <aquila-table [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                       [showAsList]="mobile"></aquila-table>
        </ng-template>
        <ng-template [ngIf]="viewChangers['mapView'].show">
          <h3 nxHeadline="subsection-medium"
              class="nx-margin-bottom-2m">{{'contract.onlineDeviceConfig' | translate}}</h3>
          <div nxRow="">
            <div nxCol="12, 12, 5">
              <nx-formfield nxLabel="{{'contract.distributorFilter' | translate}}" appearance="outline" nxFloatLabel="always">
                <nx-multi-select
                  name="selectedDistributors"
                  [(ngModel)]="selectedDistributors"
                  [options]="distributorForUsers"
                  filter
                  selectLabel="name"
                  selectValue="id"
                  (selectionChange)="filterDistributors($event)"
                ></nx-multi-select>
              </nx-formfield>
            </div>
            <div nxCol="12, 12, 7">
              <nx-formfield nxLabel="{{'contract.deviceFilter' | translate}}" appearance="outline" nxFloatLabel="always">
                <nx-multi-select
                  name="selectedDevices"
                  [(ngModel)]="selectedDevices"
                  [options]="activeDevices"
                  filter
                  selectLabel="uuId"
                  selectValue="id"
                  (selectionChange)="filterDevices($event)"
                ></nx-multi-select>
              </nx-formfield>
            </div>
          </div>
          <app-map *ngIf="mapModels && mapModels.length>0"
                   [height]="'calc(100vh - 296px)'"
                   [models]="mapModels"
                   [startZoom]="6"
                   [countryCodes]="countryCodesInAddress"
                   [markerClickSupplier]="markerClick()"
          ></app-map>
        </ng-template>
        <ng-template [ngIf]="viewChangers['calendarView'].show">
          <div nxRow="">
            <div nxCol="12, 12, 5">
              <nx-formfield nxLabel="{{'contract.distributorFilter' | translate}}" appearance="outline" nxFloatLabel="always">
                <nx-multi-select
                  name="selectedDistributors"
                  [(ngModel)]="selectedDistributors"
                  [options]="distributorForUsers"
                  filter
                  selectLabel="name"
                  selectValue="id"
                  (selectionChange)="filterDistributors($event)"
                ></nx-multi-select>
              </nx-formfield>
            </div>
            <div nxCol="12, 12, 7">
              <nx-formfield nxLabel="{{'contract.deviceFilter' | translate}}" appearance="outline" nxFloatLabel="always">
                <nx-multi-select
                  name="selectedDevices"
                  [(ngModel)]="selectedDevices"
                  [options]="activeDevices"
                  filter
                  selectLabel="uuId"
                  selectValue="id"
                  (selectionChange)="filterDevices($event)"
                ></nx-multi-select>
              </nx-formfield>
            </div>
          </div>
          <div class="scroll-container nx-margin-top-2m">
            <table nxTable style="border:none;">
              <tbody>
              <tr nxTableRow *ngFor="let item of filteredModels" style="border: 1px solid #555">
                <td style="width: 150px;border-right: 1px solid #555" nxTableCell>{{item.measuringDevice.uuId}} </td>
                <td style="width: 50px; border-right: 1px solid #555" nxTableCell>
                  <div class="status status-{{item.onlineStatus}}">
                    {{'onlineStatus.' + item.onlineStatus | translate}}</div>
                </td>
                <td style="width: 100px; border-left: 1px solid #555" nxTableCell>
                  {{item.distributor ? item.distributor.name : ''}}
                </td>
                <td nxTableCell>
                  <div class="d-flex justify-content-center align-items-center timeline" (click)="openConfigModal(item)"
                       [nxTooltip]="
                                (item.start | date:'yyyy.MM.dd') + ' - '+
                                (item.end | date:'yyyy.MM.dd')
                              "
                       style="{{'background:'+item.measuringDevice.color}}"
                  >
                    <span class="my-3 text-light" style="font-weight: bold">{{item.measuringPoint.name}}
                      ({{item.partner?.name}})</span>
                  </div>
                </td>

              </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
</nx-card>

<ng-template #translateTemplate let-value="value">
  {{'onlineStatus.' + value | translate}}
</ng-template>


