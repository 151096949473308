<ng-container *ngIf="model">
  <nx-card class="viewer">
    <nx-card-header>
      <page-show-tool-bar
        [title]="model.name"
        [backSupplier]="back()"
        [editSupplier]="edit()"
      ></page-show-tool-bar>
    </nx-card-header>
    <div nxRow="" class="nx-margin-s">
      <div nxCol="12,12,6,4,3">
       <div nxRow="">
         <div nxCol="12">
           <nx-card class="box">
             <nx-card-header>
               <p>{{'general.name' | caption}}</p>
               <h3 class="nx-margin-y-s">
                 {{model.name}}
               </h3>
             </nx-card-header>
           </nx-card>
         </div>
         <div nxCol="12">
           <nx-card class="box">
             <nx-card-header>
               <p>{{'addon.companyName' | caption}}</p>
               <h3 class="nx-margin-y-s">
                 {{model.companyName}}
               </h3>
             </nx-card-header>
           </nx-card>
         </div>
       </div>
      </div>
      <div nxCol="12,12,6,8,9" class="container">
        <nx-card class="box">
          <nx-card-header>
            <p>{{'general.description' | caption}}</p>
            <h3 class="nx-margin-y-s">
              {{model.description}}
            </h3>
          </nx-card-header>
        </nx-card>
        <nx-card class="box">
          <nx-card-header>
            <p>{{'general.partners' | caption}}</p>
            <h3 class="nx-margin-y-s">
              {{model.partners | joint: 'name'}}
            </h3>
          </nx-card-header>
        </nx-card>
      </div>
    </div>
  </nx-card>

</ng-container>
