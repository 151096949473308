import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Addon} from '../../../models/addon.model';
import {AddonService} from '../../../services/model/addon.service';
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AbstractShowPage} from "../../../models/AbstractShowPage";
import {
  DATA_DISPLAY_DEFAULT_OPTIONS,
  DataDisplayDefaultOptions,
  NxDataDisplayOrientation
} from "@aposin/ng-aquila/data-display";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {lastValueFrom, map, Observable} from "rxjs";
import {MyStorageService} from "../../../services/my-storage.service";
import {PartnerService} from "../../../services/model/partner.service";

const options: DataDisplayDefaultOptions = {
  size: 'medium',
};

@Component({
  selector: 'app-show-addons',
  templateUrl: './show-addons.component.html',
  styleUrls: ['./show-addons.component.scss'],
  providers: [
    {provide: DATA_DISPLAY_DEFAULT_OPTIONS, useValue: options,},
  ],
})
@PageTest({
  path: '/show/:id',
  pathPrefix: ROUTING.Addon.basePlural,
})
export class ShowAddonsComponent extends AbstractShowPage<Addon, AddonService> implements OnInit {
  readonly mobileViewport$ = this.viewportService.max(
    NxBreakpoints.BREAKPOINT_MEDIUM,
    100,
  );
  readonly orientation$: Observable<NxDataDisplayOrientation> =
    this.mobileViewport$.pipe(
      map(mobile => (mobile ? 'vertical' : 'horizontal-columns')),
    );

  constructor(service: AddonService, route: ActivatedRoute,
              myStorageService: MyStorageService, private readonly viewportService: NxViewportService, private partnerService: PartnerService) {
    super(service, route, myStorageService);
  }

  ngOnInit(): void {
    this.loadData().then();
    this.onInit();
  }

  private async loadData(): Promise<void> {
    let partners = await lastValueFrom(this.partnerService.getAllByDeleted());
    this.model.partners = partners.filter((p: any) => p.addons.map(a => a.addon.id).includes(this.model.id));
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }
}
