import {Injectable} from '@angular/core';
import {MeasuringDeviceRepository} from '../../repositories/measuring-device-repository';
import {ROUTING} from '../../utilities/routing-constants';
import {Router} from '@angular/router';
import {BaseModelService} from './base-model.service';
import {IrfNotificationService} from "irf-services";
import {MeasuringDevice} from "../../models/measuring-device.model";
import {ValidatorService} from "../validator.service";
import {FilterService} from "../filterer/filter.service";
import {BehaviorSubject, map} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";

export const DTO = {
  'SlimMeasuringDeviceDTO': 'SlimMeasuringDeviceDTO',
  'MeasuringDeviceDTO': 'MeasuringDeviceDTO',
  WebhookDTO: 'WebhookDTO',
  SlimWebhookDTO: 'SlimWebhookDTO'
}

@Injectable({
  providedIn: 'root'
})
export class MeasuringDeviceService extends BaseModelService<MeasuringDeviceRepository> {

  private _copyId = new BehaviorSubject<number | null>(null);

  constructor(repo: MeasuringDeviceRepository, notificationService: IrfNotificationService, router: Router, private filterer: FilterService) {
    super(ROUTING.MeasuringDevice, repo, notificationService, router);
  }

  copyId(): BehaviorSubject<number | null> {
    return this._copyId;
  }

  getAllByDeleted(deleted = false) {
    const filter = this.filterer.filter(DTO.MeasuringDeviceDTO)
      .equal('deleted', deleted)
      .create();
    return this.repo.getAllByFilter(filter).pipe(
      map(devices => this.addExtendedPropertiesForModelList(devices))
    );
  }

  getAllByForDistributor(distributorIds: number[]) {
    return this.repo.getAllForDistributor(distributorIds).pipe(
      map(devices => this.addExtendedPropertiesForModelList(devices))
    );
  }

  getAllWithoutContract(start: string, end: string) {
    return this.repo.getAllWithoutContract(start, end).pipe(
      map(devices => this.addExtendedPropertiesForModelList(devices))
    );
  }

  getAllWithoutContractForDistributor(distributorId: number, start: string, end: string) {
    return this.repo.getAllWithoutContractForDistributor(distributorId, start, end).pipe(
      map(devices => this.addExtendedPropertiesForModelList(devices))
    );
  }

  getById(id: number) {
    const filter = this.filterer.filter(DTO.MeasuringDeviceDTO)
      .equal('id', id)
      .create();
    return this.repo.getAllByFilter(filter).pipe(
      map(devices => {
        if (devices.length < 1) {
          this.notificationService.showError('general.error', 'error.measuringDevice.notExist');
          throw new HttpErrorResponse({error: 'error.measuringDevice.notFound', status: 404});
        }
        return new MeasuringDevice(this.addExtendedPropertiesForModelList(devices)[0]);
      })
    );
  }

  getByIdAsDistributor(id: number) {
    return this.repo.getByIdAsDistributor(id).pipe(
      map(device => {
        if (!device) {
          this.notificationService.showError('general.error', 'error.measuringDevice.notExist');
          throw new HttpErrorResponse({error: 'error.measuringDevice.notFound', status: 404});
        }
        return this.addExtendedPropertiesForModel(device);
      })
    );
  }


  override addExtendedPropertiesForModel(model: any): any {
    if (model.notes) {
      model.notes = model.notes.map(note => note.note).sort((a, b) => (a.id > b.id ? -1 : 1));
    }
    if (model.attachments) {
      model.attachments = model.attachments.map(attachment => attachment.attachment);
    }
    return model;
  }

  override validate(editableRow: MeasuringDevice) {
    const attributeBlackList = ['id', 'detector', 'attachments', 'notes', 'lockId', "distributor", 'distributorId']
    const attributeList = Object.keys(editableRow).filter(row => !attributeBlackList.includes(row));
    const ipRegExp = /^((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])(?::(?:[0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/;
    const macRegExp = /^[0-9a-f]{1,2}([\:-])(?:[0-9a-f]{1,2}\1){4}[0-9a-f]{1,2}$/gmi
    ValidatorService.allRequired(editableRow, attributeList, "MeasuringDevice");
    ValidatorService.matchRegExp(editableRow.localAddress, ipRegExp, 'error.measuringDevice.localAddress.regExp')
    ValidatorService.matchRegExp(editableRow.macAddress, macRegExp, 'error.measuringDevice.macAddress.regExp')
  }

}
