<form class="form-example mt-3">
  <div nxLayout="grid">
    <div nxRow="">
      <div nxCol="12,12,12,6">
        <app-input
          captionCode="webhook.type"
          type="text"
          [required]="true"
          [disabled]="true"
          [placeholder]="('webhook.'+model.type | caption)"
        ></app-input>
      </div>
      <div nxCol="12,12,12,6">
        <ng-container *ngIf="partners.length>1">
          <nx-formfield nxLabel="{{'webhook.partner' | caption}}">
            <nx-dropdown name="partnerId" [(ngModel)]="model.partnerId">
              <nx-dropdown-item *ngFor="let partner of partners"
                                [nxValue]="partner.id">{{partner.name}}</nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </ng-container>

        <ng-container *ngIf="partners.length==1">
          <app-input
            captionCode="webhook.partner"
            type="text"
            name="partner"
            [required]="true"
            [disabled]="true"
            [(ngModel)]="model.partner.name"
          ></app-input>
        </ng-container>

        <ng-container *ngIf="partners.length<1">
          GEBASZ VAN
        </ng-container>
      </div>
      <div nxCol="12,12,12,6">
        <app-input
          captionCode="webhook.signatureUrl"
          type="text"
          name="signatureUrl"
          [required]="true"
          [(ngModel)]="model.signatureUrl"
          [ngClass]="ValidatorService.isValidUrl(model.signatureUrl) ? 'validUrl' : 'invalidUrl'"
        ></app-input>
      </div>
      <div nxCol="12,12,12,6">
        <app-input
          captionCode="webhook.url"
          type="text"
          name="url"
          [required]="true"
          [(ngModel)]="model.url"
          [ngClass]="ValidatorService.isValidUrl(model.url) ? 'validUrl' : 'invalidUrl'"
        ></app-input>
      </div>
    </div>
  </div>
</form>
