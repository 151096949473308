import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContractsComponent} from './contracts/contracts.component';
import {RouterModule} from '@angular/router';
import {EditContractsComponent} from './edit-contracts/edit-contracts.component';
import {ShowContractsComponent} from './show-contracts/show-contracts.component';
import {NxCardModule} from '@aposin/ng-aquila/card';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {ComponentsModule} from '../../components/components.module';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {FormsModule} from '@angular/forms';
import {NxDatefieldModule} from "@aposin/ng-aquila/datefield";
import {NxDropdownModule} from "@aposin/ng-aquila/dropdown";
import {NxMessageModule} from "@aposin/ng-aquila/message";
import {FullCalendarModule} from "@fullcalendar/angular";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import {NgApexchartsModule} from "ng-apexcharts";
import {NxSwitcherModule} from "@aposin/ng-aquila/switcher";
import {NxModalModule} from "@aposin/ng-aquila/modal";
import {PipeModule} from "../../pipe/pipe.module";

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    ContractsComponent,
    EditContractsComponent,
    ShowContractsComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        NxCardModule,
        NxIconModule,
        ComponentsModule,
        NxHeadlineModule,
        NxGridModule,
        NxButtonModule,
        NxFormfieldModule,
        NxInputModule,
        FormsModule,
        NxDatefieldModule,
        NxDropdownModule,
        NxMessageModule,
        FullCalendarModule,
        NgApexchartsModule,
        NxSwitcherModule,
        NxModalModule,
        PipeModule,
    ]
})
export class ContractModule {
}
