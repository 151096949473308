import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (value) {
      const isoDateString = `${value.replace(' ', 'T')}Z`;
      const seconds = Math.floor((+new Date() - +new Date(isoDateString)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Now';
      const intervals = {
        'years': 31536000,
        'months': 2592000,
        'weeks': 604800,
        'days': 86400,
        'hours': 3600,
        'minutes': 60,
        'seconds': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          return counter + ' ' + i + ' ago'; // singular (1 day ago)
        }
      }
    }
    return value;
  }


}
