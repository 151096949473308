import {NoteModel} from "./note.model";
import {BaseModel} from "./base.model";
import {Distributor} from "./distributor.model";
export type MEASURING_DEVICE_STATUSES = 'ACTIVE'| 'UNDER_CONSTRUCTION' |'FAULTY' | 'UNDER_REPAIR';
export class MeasuringDevice implements BaseModel {
  id: number;
  externalId:number;
  macAddress: string = 'dddd';
  ssid: string = '';
  wifiPassword: string = '';
  online: boolean = false;
  virtual: boolean = false;
  apiKey: string = '';
  uuId: string = '';
  healthCheckEndpoint:string='';
  detectorId: number;
  note?: NoteModel;
  notes:NoteModel[];
  localAddress: string = '';
  globalAddress: string = '';
  attachments: any[];
  attachment?: any = null;
  detector?: any;
  status:MEASURING_DEVICE_STATUSES = 'ACTIVE';
  color:string='';
  distributor:Distributor;
  distributorId:number;

  constructor(obj: any = undefined) {
    if (obj) {
      try {
        this.id = obj.id;
        this.externalId = obj.externalId;
        this.macAddress = obj.macAddress;
        this.ssid = obj.ssid;
        this.wifiPassword = obj.wifiPassword;
        this.online = obj.online;
        this.virtual = obj.virtual;
        this.apiKey = obj.apiKey;
        this.uuId = obj.uuId;
        this.healthCheckEndpoint = obj.healthCheckEndpoint;
        this.detectorId = obj.detectorId;
        this.note = obj.note;
        this.notes = obj.notes;
        this.localAddress = obj.localAddress;
        this.globalAddress = obj.globalAddress;
        this.attachments = obj.attachments;
        this.attachment = obj.attachment;
        this.detector = obj.detector;
        this.status = obj.status;
        this.color = obj.color;
        this.distributor = obj.distributor;
        this.distributorId = obj.distributorId;
      } catch {

      }
    }
  }

  clone(): MeasuringDevice {
    const clone = new MeasuringDevice();

    clone.externalId = this.externalId;
    clone.macAddress = this.macAddress;
    clone.ssid = this.ssid;
    clone.wifiPassword = this.wifiPassword;
    clone.online = this.online;
    clone.virtual = this.virtual;
    clone.apiKey = this.apiKey;
    clone.uuId = this.uuId;
    clone.healthCheckEndpoint = this.healthCheckEndpoint;
    clone.detectorId = this.detectorId;
    clone.note = this.note;
    clone.notes = this.notes;
    clone.localAddress = this.localAddress;
    clone.globalAddress = this.globalAddress;
    clone.attachments = this.attachments;
    clone.attachment = this.attachment;
    clone.detector = this.detector;
    clone.status = this.status;
    clone.color = this.color;
    clone.distributor = this.distributor;
    clone.distributorId = this.distributorId;

    return clone;
  }
}

