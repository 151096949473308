import {ModelPath} from '../../utilities/routing-constants';
import {Router} from '@angular/router';
import {BaseRepository} from 'ng-pository';
import {EmptyError} from "rxjs";
import {IrfNotificationService} from "irf-services";
import {Distributor} from "../../models/distributor.model";

export abstract class BaseModelService<REPO extends BaseRepository<any>> {

  protected constructor(public readonly modelPath: ModelPath,
                        public repo: REPO,
                        public notificationService: IrfNotificationService,
                        public router: Router) {
  }

  abstract getById(id: any, other?:any)

  public getRoutingPath(additionalPath?: string): string {
    let tail: string = '';
    if (additionalPath) {
      tail += (additionalPath.startsWith('/')) ? additionalPath : ('/' + additionalPath);
    }
    return this.modelPath.full + tail;
  }

  public navigateToBase(): void {
    this.router.navigate([this.getRoutingPath()]);
  }

  public navigateToUrl(url:string):void{
    this.router.navigate([url]);
  }

  public new(prefix:string="",postfix:string="") {
    this.router.navigate([this.getRoutingPath(prefix+'edit'+postfix)]);
  }

  public show(id: number | string): void {
    this.router.navigate([this.getRoutingPath('show'), id]);
  }

  public edit(id: number | string, secondPart?:any): void {
    if(secondPart) {
      this.router.navigate([this.getRoutingPath('edit'), id, secondPart]);
    }else{

      this.router.navigate([this.getRoutingPath('edit'), id]);
    }
  }

  addExtendedPropertiesForModel(model: any): any {
    return model;
  }

  addExtendedPropertiesForModelList(modelList: any[]): any[] {
    modelList.forEach(model => model = this.addExtendedPropertiesForModel(model));
    return modelList;
  }

  validate(model: any) {

  }

  public validationErrorHandler(e: any) {
    const b = !(e instanceof EmptyError);
    if (b) {
      this.notificationService.showWarning('general.warning', e.message.toLowerCase());
      console.log(e);
    }
  }

  navigateToBaseOrDetailedView(id: number | string, navigateToView: boolean) {
    if (navigateToView) {
      this.show(id);
    } else {
      this.navigateToBase();
    }
  }

}
