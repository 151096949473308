import {User} from '../models/user.model';
import {MeasuringDevice} from '../models/measuring-device.model';
import {MeasuringPoint} from '../models/measuring-point.model';
import {Partner} from '../models/partner.model';
import {Contract} from '../models/contract.model';
import {Addon} from '../models/addon.model';
import {Detector} from '../models/detector.model';
import {Attachment} from '../models/attachment.model';
import {Distributor} from "../models/distributor.model";
import {Webhook} from "../models/webhook.model";
import {ReportEmailModel} from "../models/report-email.model";

export type ROLE = 'admin' | 'partner' | 'configurator' | 'distributor';

export class ModelPath {
  public readonly role: ROLE;
  public readonly basePlural: string;
  private readonly _full: () => string = this.pathConcat; //    /role/base

  private constructor(role: ROLE, basePath: string) {
    this.role = role;
    this.basePlural = this.pluralize(this.parse(basePath));
  }

  public static admin(modelName: string): ModelPath {
    return new ModelPath('admin', modelName);
  }

  public static distributor(modelName: string): ModelPath {
    return new ModelPath('distributor', modelName);
  }

  public static partner(modelName: string): ModelPath {
    return new ModelPath('partner', modelName);
  }

  public static configurator(modelName: string): ModelPath {
    return new ModelPath('configurator', modelName);
  }

  get full(): string {
    return this._full();
  }

  private pathConcat(): string {
    return '/' + this.role + '/' + this.basePlural;
  }

  private parse(modelName: string): string {
    let result: string = modelName.charAt(0).toLowerCase()

    for (let character of modelName.substring(1).split('')) {
      if (character == character.toUpperCase()) {
        result = result + '-';
      }
      result = result + character.toLowerCase();
    }

    return result;
  }

  private pluralize(base: string): string {
    return base + 's';
  }
}

export const ROUTING = {
  User: ModelPath.admin(User.name),
  MeasuringDevice: ModelPath.admin(MeasuringDevice.name),
  MeasuringPoint: ModelPath.admin(MeasuringPoint.name),
  MeasuringPointDistributor: ModelPath.distributor(MeasuringPoint.name),
  Partner: ModelPath.admin('customer'),
  Distributor: ModelPath.admin(Distributor.name),
  Contract: ModelPath.admin(Contract.name),
  ContractDistributor: ModelPath.distributor(Contract.name),
  Addon: ModelPath.admin(Addon.name),
  Detector: ModelPath.admin(Detector.name),
  Attachment: ModelPath.admin(Attachment.name),
  Webhook: ModelPath.partner(Webhook.name),
  ReportEmail: ModelPath.partner(ReportEmailModel.name),
  ApiKeys: 'api-keys',
}
