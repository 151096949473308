import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'shortenIfMobile'
})
export class ShortenIfMobilePipe implements PipeTransform {

  transform(input: string, isMobile: boolean): string {
    if (isMobile && input != undefined) {
      const displayLength = 18;
      if (input.length > displayLength) {
        return input.substring(0, displayLength) + '...';
      }
    }
    return input;
  }

}
