import {Component, OnInit} from '@angular/core';
import {lastValueFrom, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Detector} from '../../../models/detector.model';
import {DetectorService} from '../../../services/model/detector.service';
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {MyStorageService} from "../../../services/my-storage.service";

@Component({
  selector: 'app-edit-detectors',
  templateUrl: './edit-detectors.component.html',
  styleUrls: ['./edit-detectors.component.scss']
})
@PageTest({
  path: ['edit', 'edit/:id'],
  pathPrefix: ROUTING.Detector.basePlural,
  layout: AdminLayoutComponent
})
export class EditDetectorsComponent implements OnInit {

  private sub: Subscription;
  model: Detector = new Detector();

  constructor(private service: DetectorService,
              private route: ActivatedRoute,
              private myStorageService: MyStorageService) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(async params => {
      let id = params['id'];
      if (id) {
        this.model = await lastValueFrom(this.service.getById(id));
        this.model.config = this.model.defaultConfig.rawConfigJson;
        if (this.model.algorithm) {
          this.model.algorithmId = this.model.algorithm.id
        }
      }
      if (this.model == null) {
        this.model = new Detector();
      }
    });
  }

  save(): () => void {
    return async () => {
      try {
        this.service.validate(this.model);
        if (!this.model.id) {
          this.service.repo.save(this.model).subscribe(async response => {
            await lastValueFrom(this.service.repo.addAlgorithm(response.id, this.model.algorithmId));
          });
        } else {
          if (this.model.algorithm) {
            await lastValueFrom(this.service.repo.removeAlgorithm(this.model.id));
          }
          this.service.repo.update(this.model.id, this.model).subscribe(async response => {
            await lastValueFrom(this.service.repo.addAlgorithm(this.model.id, this.model.algorithmId));
          })
        }
        await this.service.notificationService.showSuccess('general.success', 'general.success')
        this.service.navigateToBaseOrDetailedView(this.model.id, this.myStorageService.getFromCookies('NAVIGATE_TO_DETAILED_VIEW_AFTER_EDIT', false));
      } catch (e: any) {
        this.service.validationErrorHandler(e);
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  cancel(): () => void {
    return () => {
      this.service.navigateToBaseOrDetailedView(this.model.id, this.myStorageService.getFromCookies('NAVIGATE_TO_DETAILED_VIEW_AFTER_EDIT', false));
    }
  }
}
