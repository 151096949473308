import {Injectable} from "@angular/core";
import {AddressModel} from "../components/modal/add-address-to-model/address.model";

@Injectable({
  providedIn: 'root'
})
export abstract class ValidatorService {


  public static backendMapIsEmpty(map: any, errorMessage: string) {
    if (!map || Object.keys(map).length < 1) {
      throw new Error(errorMessage);
    }
  }

  /**
   * Megvizsgálja a @value értéket, hogy ki van-e töltve, ha nincs, akkor @errorMessage hibát dob
   * @param value
   * @param errorMessage
   */
  public static required(value: any, errorMessage: string) {
    if (value == null || value.length == 0 || value == {}) {
      throw new Error(errorMessage)
    }
  }

  /**
   * Az @object összes olyan tulajdonságát megvizsgálja, mely szerepel az @attributeList-ben
   * @param object
   * @param attributeList
   * @param modelName - vizsgált objektum neve, csak a hibaüzenethez kell
   */
  public static allRequired(object: any, attributeList: any[], modelName: string) {
    attributeList.forEach(attribute => {
      this.required(object[attribute], "error." + modelName + ".required." + attribute)
    })
  }

  public static matchRegExp(value:string, regExp:any, errorMessage='error.regExpError'){
    if(!regExp.test(value)){
      throw new Error(errorMessage)
    }
  }

  public static validateStartEndDate(start:Date, end:Date, startCanBeInPast = false){
    if(!startCanBeInPast && start.getTime()< (new Date()).getTime()){
      throw new Error("error.date.startInPast");
    }
    if(end.getTime()<start.getTime()){
      throw new Error("error.date.endBeforeStart");
    }
  }


  /**
   * Egy Address model-t validál
   * @param address
   * @param modelName - vizsgált objektum neve, csak a hibaüzenethez kell
   */
  public static validateAddress(address: AddressModel, modelName: string) {
    if ((address.country == null || address.country.length < 1) ||
      (address.countryCode == null || address.countryCode.length < 1) ||
      (address.city == null || address.city.length < 1) ||
      (address.address == null || address.address.length < 1) ||
      (address.houseNumber == null || address.houseNumber.length < 1)
      //||
      // (address.zipCode == null || address.zipCode < 1000 || address.zipCode > 9999)
    ) {
      throw new Error("error." + modelName + ".address.invalid");
    }
  }

  public static validateUrl(url: string, errorMessage: string = 'invalid url!') {
    if (!this.isValidUrl(url)) {
      throw new Error(errorMessage);
    }
  }

  public static isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }
}
