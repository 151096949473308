import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ContractService} from "../../../../services/model/contract.service";
import {lastValueFrom} from "rxjs";
import {MyStorageService} from "../../../../services/my-storage.service";
import {AUTH_USER_KEY, DEVICE_API_KEY, DISTRIBUTOR_KEY} from "../../../../utilities/constants";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {
  ShowOnlineDeviceComponent,
  ShowOnlineDeviceModalInput
} from "../../../../components/modal/show-online-device/show-online-device.component";
import {PageTest} from "../../../../utilities/PageTest";
import {ConfiguratorLayoutComponent} from "../../../../layouts/configurator-layout/configurator-layout.component";
import {MarkerElement} from "../../../../components/map/map.component";
import {TableAction, TableCol, TableFilter} from "ng-aquila-datatable";
import {Contract, ONLINE_STATUS, ONLINE_STATUS_LIST} from "../../../../models/contract.model";
import {VIEW_CHANGER_CONTAINER, ViewChanger} from "../../../../components/list-view-header/list-view-header.component";
import {AbstractListPage} from "../../../../models/AbstractListPage";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {AbstractCaptionService} from "irf-caption";
import {WebsocketService, WS_ENDPOINTS} from "../../../../services/websocket.service";
import {Utilities} from "../../../../utilities/utilities";
import {ConfiguratorRepository} from "../../../../repositories/configurator-repository";
import {Distributor} from "../../../../models/distributor.model";
import {MeasuringDevice} from "../../../../models/measuring-device.model";
import {MeasuringPoint} from "../../../../models/measuring-point.model";
import {LoaderService} from "../../../../services/loader.service";
import {DistributorService} from "../../../../services/model/distributor.service";

export const FILTER_KEY = {
  DISTRO_FILTER_KEY: 'DISTRO_FILTER_KEY',
  POINT_FILTER_KEY: 'POINT_FILTER_KEY',
  DEVICE_FILTER_KEY: 'DEVICE_FILTER_KEY'
}

@Component({
  selector: 'app-configuration-start',
  templateUrl: './configuration-start.component.html',
  styleUrls: ['./configuration-start.component.scss']
})
@PageTest({
  path: '',
  layout: ConfiguratorLayoutComponent
})
export class ConfigurationStartComponent extends AbstractListPage<Contract[], ContractService> implements OnInit, OnDestroy {
  @ViewChild('translateTemplate') translateTpl: TemplateRef<any>;
  mapModels: MarkerElement[];
  componentDialogRef!: NxModalRef<any>;

  filteredModels: Contract[];

  colDef: TableCol[] = [];
  actionDef: TableAction[];
  viewChangers: VIEW_CHANGER_CONTAINER;
  private lockDeviceIds: number[] = [];
  countryCodesInAddress: string[] = [];
  distributorForUsers: Distributor[];
  selectedDistributors: number[] = [];
  selectedDevices: number[] = [];

  activeDevices: MeasuringDevice[] = [];
  activePoints: MeasuringPoint[] = [];
  isReady: boolean = false;

  constructor(viewportService: NxViewportService, cdr: ChangeDetectorRef, translateService: AbstractCaptionService, service: ContractService, myStorageService: MyStorageService,
              public dialogService: NxDialogService,
              private ngZone: NgZone,
              private loaderService: LoaderService,
              private websocketService: WebsocketService,
              private distributorService: DistributorService) {
    super(viewportService, cdr, translateService, service, myStorageService);
    this.viewChangers = {
      'mapView': new ViewChanger('mapView', false, 'map'),
      'calendarView': new ViewChanger('calendarView', true, 'calendar'),
      'tableView': new ViewChanger('tableView', false, 'table'),
    }
    this.eventWhereMobileIsTrue = () => {
      this.viewChangers['tableView'].show = this.mobile;
      this.viewChangers['calendarView'].show = !this.mobile;
    }
  }


  async ngOnInit(): Promise<void> {
    this.isReady = false;
    this.loaderService.show()
    this.selectedDevices = this.myStorageService.getFromStore(FILTER_KEY.DEVICE_FILTER_KEY, []);
    this.selectedDistributors = this.myStorageService.getFromStore(FILTER_KEY.DISTRO_FILTER_KEY, []);

    const authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, undefined);

    if (authUser) {
      this.distributorForUsers = await lastValueFrom(this.distributorService.getDistributorForUser(authUser.id));
      this.myStorageService.saveToCookies(DISTRIBUTOR_KEY, this.distributorForUsers);
    } else {
      this.distributorForUsers = [];
    }

    this.models = await lastValueFrom(this.service.getAllActiveWithOnlineDevice(this.distributorForUsers.map(d => d.id)));
    this.countryCodesInAddress = [];
    this.countryCodesInAddress = [...new Set(this.models.map(model => model.measuringPoint ? model.measuringPoint.address.countryCode.toLowerCase() : 'hu'))];
    this.mapModels = [];
    this.activePoints = [];
    this.activeDevices = [];
    for (const model of this.models) {
      this.initActiveDevice(model);
      this.initActivePoints(model);
      this.loadIsAlive(model);
      model.interval = setInterval(() => {

        this.loadIsAlive(model);
      }, 10000);
    }
    await this.initTableData();
    this.connectToWsEndpoints();
    this.filterModels(this.fullFilterPredicate());
    this.isReady = true;
    this.loaderService.hide();
  }

  private initActivePoints(model: Contract) {
    if (!this.activePoints.map(p => p.id).includes(model.measuringPoint.id)) {
      this.activePoints.push(model.measuringPoint);
    }
  }

  private initActiveDevice(model: Contract) {
    if (!this.activeDevices.map(d => d.id).includes(model.measuringDevice.id)
      && model.distributor && this.selectedDistributors.includes(model.distributor.id)) {
      this.activeDevices.push(model.measuringDevice);
    }
  }

  private async initTableData() {
    this.actionDef = [
      new TableAction('cog', this.config(), await this.getTranslate('table.action.config')),
    ];
    this.colDef = [
      //TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('onlineStatus', await this.getTranslate('contract.status'),
        TableFilter.newSelect(ONLINE_STATUS_LIST),
        () => {
          return this.translateTpl
        }
      ),
      TableCol.newString('measuringDevice.uuId', await this.getTranslate('contract.device')),
      TableCol.newString('measuringPoint.name', await this.getTranslate('contract.point')),
      TableCol.newString('partner.name', await this.getTranslate('contract.partner')),
      TableCol.newString('distributor.name', await this.getTranslate('contract.distributor')),
      TableCol.newDate('start', await this.getTranslate('contract.start')),
      TableCol.newDate('end', await this.getTranslate('contract.end')),
    ];
  }

  private loadIsAlive(model: Contract) {
    ConfiguratorRepository.showLoader = false;
    this.service.checkUrlIsAvailable(model.measuringDevice?.healthCheckEndpoint).then(async result => {
      model.onlineStatus = this.getOnlineStatus(result);
      model.checkResult = result;
      if (this.filteredModels.map(c => c.id).includes(model.id)) {
        this.mapModels.push(this.service.convertModelToMarkerElement(model));
      }
      ConfiguratorRepository.showLoader = true;
    }).catch(error => {
      ConfiguratorRepository.showLoader = true;
    });
  }

  private connectToWsEndpoints() {
    this.websocketService.connectToEndpoint(WS_ENDPOINTS.LOCK, async (response) => {
      if (response.subscriber != this.authUser.email) {
        if (!this.lockDeviceIds.includes(response.attribute.id)) {
          this.lockDeviceIds.push(response.attribute.id);
        }
        const messages = await this.translateService.getCaption('onlineDevice.lock');
        this.service.notificationService.showInfo('general.info', messages.nev + ": " + response.attribute.uuId);
      }
    });
    this.websocketService.connectToEndpoint(WS_ENDPOINTS.UNLOCK, async (response) => {
      if (response.subscriber != this.authUser.email) {
        const index = this.lockDeviceIds.indexOf(response.attribute.id);
        if (index > -1) {
          this.lockDeviceIds.splice(index, 1);
        }
        this.models.forEach(contract => {
          if (contract.locked && contract.measuringDevice && contract.measuringDevice.id == response.attribute.id) {
            contract.locked = false;
          }
        });
        const messages = await this.translateService.getCaption('onlineDevice.unlock');
        this.service.notificationService.showInfo('general.info', messages.nev + ": " + response.attribute.uuId);
      }
    });
  }

  markerClick(): (event: any) => void {
    return (e) => {
      const contract = this.models.find(c => c.id == e.target.options.markerId);
      if (contract) {
        this.openConfigModal(contract)
      }
    }
  }

  config(): (row: Contract) => void {
    return (row) => {
      this.openConfigModal(row);
    }
  }

  openConfigModal(contract: Contract) {
    if (contract && contract.onlineStatus != 'offline') {
      if (contract.measuringDevice && this.lockDeviceIds.includes(contract.measuringDevice.id)) {
        contract.locked = true;
      }
      const s = 'modal-contract-' + contract.id;
      this.componentDialogRef = this.dialogService.open(
        ShowOnlineDeviceComponent,
        Utilities.getDefaultModalConfig(new ShowOnlineDeviceModalInput(contract, s), s)
      );
      this.componentDialogRef.afterClosed().subscribe(result => {
        this.myStorageService.deleteFromStore(DEVICE_API_KEY);
      });
    } else {
      this.service.notificationService.showWarning('general.warning', 'error.deviceIsNotOnline');
    }
  }


  ngOnDestroy(): void {
    this.websocketService.disconnectFromEndpoint(WS_ENDPOINTS.LOCK);
    this.websocketService.disconnectFromEndpoint(WS_ENDPOINTS.UNLOCK);
    this.models.forEach(model => {
      if (model.interval) {
        clearInterval(model.interval);
      }
    });
  }

  private getOnlineStatus(result: any): ONLINE_STATUS {
    if (result == 'NOPE') {
      return "offline"
    }
    if (result.message && result.message == 'Online') {
      return "online"
    }
    return "reachable";
  }


  filterDistributors($event: number[]) {
    console.log(this.selectedDistributors);
    this.selectedDistributors = $event;
    this.myStorageService.saveToStore(FILTER_KEY.DISTRO_FILTER_KEY, this.selectedDistributors);
    this.selectedDevices = [];
    this.activeDevices = [];
    this.filterModels(this.distributorPredicate());

    const selectedDevice = this.selectedDevices;
    this.filteredModels.forEach(model => {
      this.initActiveDevice(model);
      if (!selectedDevice.includes(model.measuringDevice.id)) {
        selectedDevice.push(model.measuringDevice.id);
      }
    });
    this.filterDevices(selectedDevice);
  }

  filterDevices($event: number[]) {
    console.log($event);
    this.selectedDevices = $event;
    this.myStorageService.saveToStore(FILTER_KEY.DEVICE_FILTER_KEY, this.selectedDevices);
    this.filterModels(this.fullFilterPredicate());
  }

  private filterModels(predicate: (contract: Contract) => boolean) {
    this.filteredModels = this.models.filter(contract => {
      if (contract.distributor) {
        return predicate(contract);

      }
      return false;
    });

    this.mapModels = [];
    setTimeout(()=>{
      this.filteredModels.forEach(model => {
        this.mapModels.push(this.service.convertModelToMarkerElement(model));
      });
    });

  }

  private devicePredicate() {
    return (contract: Contract) => {
      return this.selectedDevices.includes(contract.measuringDevice.id);
    }
  }

  private distributorPredicate() {
    return (contract: Contract) => {
      if (contract.distributor) {
        return this.selectedDistributors.includes(contract.distributor.id);
      }
      return false;
    }
  }

  private fullFilterPredicate() {
    return (contract: Contract) => {
      return this.distributorPredicate()(contract)
        && this.devicePredicate()(contract);
    };
  }


}
