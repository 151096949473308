<ng-container *ngIf="model">
  <nx-card class="p-0 w-100">
    <nx-card-header>
      <page-show-tool-bar class="d-block d-xl-none"
        [title]="model.name + ' (' + (model.start | date:'yyyy.MM.dd') + '-' + (model.end | date:'yyyy.MM.dd') + ')' | shortenIfMobile:true"
        [backSupplier]="back()"
        [editSupplier]="edit()"
      ></page-show-tool-bar>
      <page-show-tool-bar class="d-none d-xl-block"
                          [title]="model.name + ' (' + (model.start | date:'yyyy.MM.dd') + '-' + (model.end | date:'yyyy.MM.dd') + ')'"
                          [backSupplier]="back()"
                          [editSupplier]="edit()"
      ></page-show-tool-bar>
    </nx-card-header>
    <app-map [models]="[mapModel]" [startZoom]="15" [showYourPosition]="false"
             [countryCodes]="[model.measuringPoint?model.measuringPoint.address.countryCode:'hu']"></app-map>
    <div nxRow="" class="nx-margin-s">
      <div nxCol="12, 12, 12,6">
        <h4 nxHeadline="subsection-large" class="nx-margin-bottom-s">
          {{'contract.partnerHeading' | caption}}
        </h4>
        <inside-show-partner [model]="model.partner"></inside-show-partner>

      </div>
      <div nxCol="12, 12, 12,6">
        <h4 nxHeadline="subsection-large" class="nx-margin-bottom-s">
          {{'contract.pointHeading' | caption}}
        </h4>
        <app-show-measuring-point-data [withNavigation]="true" [withImage]="true"
                                       [model]="model.measuringPoint"></app-show-measuring-point-data>
      </div>
      <div nxCol="12, 12, 12,6">
        <h4 nxHeadline="subsection-large" class="nx-margin-bottom-s">
          {{'contract.deviceHeading' | caption}}
        </h4>

        <inside-show-measuring-device [model]="model.measuringDevice"></inside-show-measuring-device>
      </div>
      <div nxCol="12, 12, 12,6">
        <h4 nxHeadline="subsection-large" class="nx-margin-bottom-s">
          {{'contract.detectorHeading' | caption}}
        </h4>
        <inside-show-detector *ngIf="model.measuringDevice?.detector"
                              [model]="model.measuringDevice?.detector"></inside-show-detector>

        <nx-message nxContext="warning" *ngIf="!model.measuringDevice?.detector" class="nx-margin-bottom-xs">
          {{'contract.detectorIsNull' | caption}}
        </nx-message>
      </div>
    </div>
  </nx-card>
</ng-container>




